/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Parceiro {
  constructor () {
    this.parceiro_id = uuidV4()
    this.descricao = ''
    this.cnpj_cpf = ''
    this.nome = ''
    this.contato = ''
    this.telefone = ''
    this.logrado = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.cep = ''
    this.municipio_id = ''
    this.observacoes = ''
    this.desativado = false
    this.comissao_mensalidade = 0
    this.comissao_instalacao = 0
    this.tp_chavepix = 1
    this.chavepix = ''


    // this.cnpj_cpf = (parseInt(Math.random()*8+1))+'43480750'+(parseInt(Math.random()*9))+''+(parseInt(Math.random()*9))
    // this.nome = 'Nome de Teste ' + parseInt( Math.random()*100+1)
    // this.contato = 'Sem contato'
    // this.telefone = '3333211234'
    // this.logrado = 'Rua XXXXXX'
    // this.numero = '11'
    // this.complemento = ''
    // this.bairro = 'Centro'
    // this.cep = '36900000'
    // this.municipio_id = '3139409'
    // this.observacoes = ''
    // this.desativado = false
    // this.periodo_inicio = '202502'
    // this.comissao_mensalidade = 1
    // this.comissao_instalacao = 1
    // this.tp_chavepix = 1
    // this.chavepix = this.cnpj_cpf

  }
}
