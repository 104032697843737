/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {ParceiroSCH, getParceiroSCH} from "../../search/ParceiroSCH"
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {Parceiro} from '../../models/parceiro'
import {EventBus} from '../../helpers/event-bus'

const url = '/parceiros'

const state = {
  all: [],
  allList: [],
  parceiro: new Parceiro(),
  filtro: new Filtro()
}

const getters = {
  listaAllParceiros: (state) => {
    return state.allList !== null && state.allList !== undefined ? state.allList : []
  },
  listaParceiros: (state) => {
    return state.all
  },
  pegaParceiro: (state) => {
    return state.parceiro
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['cnpj_cpf','nome','contato','telefone'],
     '%'+search+'%',
     'ilike'
    )
  },
  async getParceiro({commit}, {parceiro_id,onSucesso, onErro}) {
   try{
     var filtro = getParceiroSCH('parceiro_id', parceiro_id, '=')
     const list = (await pegaDados(url+'/get', filtro)).data;
     commit('SET_PARCEIRO', list.data.length>0 ? list.data[0] : new Parceiro())
     onSucesso(list.data.length>0)
   } catch (error) {
     onErro()

   }

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ParceiroSCH(perPage))
  },
  async setAllListParceiros({commit},{onSucesso,onErro}) {

    try {
      const list = (await pegaDados(url + '/get_all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }

  },

  async setAllParceiros({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_PARCEIROS', list)
      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async salvarFaturas({commit},{onSucesso,onErro}) {
    try {
      const list = (await processar('faturas_parceiros/commit',[],'gravar' )).data
      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async processarParceiro({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao==='gravar'?'/commit':'/delete'), state.parceiro, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setParceiro({commit}, parceiro) {
    commit('SET_PARCEIRO', parceiro)
  },
}

const mutations = {
  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PARCEIROS(state, parceiros) {
    state.all = parceiros
  },
  SET_PARCEIRO(state, parceiro) {
    state.parceiro = parceiro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
