/* eslint-disable */
import {Filtro} from '../models/filtro/filtro'

export const getFaturaParceiroSCH = (campo, valor, condicao) => {
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo, valor, condicao)
  filtro.fields = ['*']
  return filtro
}
export const FaturaParceiroSCH = (perPage) => {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['*'];
  // filtro.addOrder('dt_pagto', 'asc')

  return filtro
}
