/* eslint-disable */
import {nao_autorizado, pegaDados, processaArquivo, processar} from '../../services/GenericAPI'
import {FaturaParceiroSCH, getFaturaParceiroSCH} from '../../search/FaturaParceiroSCH'
import {Filtro} from '../../models/filtro/filtro'
import {FaturaParceiro} from '../../models/fatura_parceiro'

const url = '/faturas_parceiros'

const state = {
  all: [],
  fatura_parceiro: new FaturaParceiro(),
  filtro: new Filtro()
}

const getters = {
  listaFaturaParceiros: (state) => {
    return state.all
  },
  pegaFaturaParceiro: (state) => {
    return state.fatura_parceiro
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['fatura_id','servico_id','valor','valor_parceiro','clientes.razao','clientes.fantasia','parceiros.nome'],
     '%'+search+'%',
     'ilike'
    )
  },
  async getFaturaParceiro({commit}, {fatura_parceiro_id,onSucesso, onErro}) {
   try{
     var filtro = getFaturaParceiroSCH('fatura_parceiro_id', fatura_parceiro_id, '=')
     const list = (await pegaDados(url+'/get', filtro)).data;
     commit('SET_FATURA_PARCEIRO', list.data.length>0 ? list.data[0] : new FaturaParceiro())
     onSucesso(list.data.length>0)
   } catch (error) {
     onErro()

   }

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', FaturaParceiroSCH(perPage))
  },

  async setAllFaturaParceiros({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_view', state.filtro)).data
      commit('SET_FATURA_PARCEIROS', list)
      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async sincronizarFaturas({commit},{onSucesso,onErro}) {
    try {
      const list = (await processar(url+'/commit',[],'gravar' )).data
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },

  async printRelatorio({commit},{filtro,onSucesso,onErro}) {
    try {
      const list = (await processaArquivo(url + '/print', filtro))
      onSucesso(list)
    } catch (error) {
      console.log('Erro',error)
      onErro()
      nao_autorizado(error)
    }
  },

  async processarFaturaParceiro({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao==='gravar'?'/commit':'/delete'), state.fatura_parceiro, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setFaturaParceiro({commit}, fatura_parceiro) {
    commit('SET_FATURA_PARCEIRO', fatura_parceiro)
  },
}

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_FATURA_PARCEIROS(state, fatura_parceiros) {
    state.all = fatura_parceiros
  },
  SET_FATURA_PARCEIRO(state, fatura_parceiro) {
    state.fatura_parceiro = fatura_parceiro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
