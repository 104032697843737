/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class FaturaParceiro {
  constructor (id) {
    this.fatura_parceiro_id = uuidV4()
    this.parceiro_id = id
    this.cliente_id = ''
    this.parceiro_id = ''
    this.periodo = ''
    this.servico_id = ''
    this.dt_pagto = ''
    this.valor = ''
    this.valor_parceiro = ''

    // this.cnpj_cpf = (parseInt(Math.random()*8+1))+'43480750'+(parseInt(Math.random()*9))+''+(parseInt(Math.random()*9))
    // this.nome = 'Nome de Teste ' + parseInt( Math.random()*100+1)
    // this.contato = 'Sem contato'
    // this.telefone = '3333211234'
    // this.logrado = 'Rua XXXXXX'
    // this.numero = '11'
    // this.complemento = ''
    // this.bairro = 'Centro'
    // this.cep = '36900000'
    // this.municipio_id = '3139409'
    // this.observacoes = ''
    // this.desativado = false
    // this.periodo_inicio = '202502'
    // this.comissao_mensalidade = 1
    // this.comissao_instalacao = 1
    // this.tp_chavepix = 1
    // this.chavepix = this.cnpj_cpf

  }
}
