/* eslint-disable */
import {nao_autorizado, pegaDados} from '../../services/GenericAPI'

const url = 'auxs_data'

const state = {
  all: [],
}

const getters = {
  ListaAll: (state) => {
    return state.all
  },
}
const actions = {

  async setAllAuxData({commit}, {filtro,onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_list', filtro)).data
      commit('SET_AUX_DATA', list)
      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },

}

const mutations = {
  SET_AUX_DATA(state, all) {
    state.all= all
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
