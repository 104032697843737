<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div id="header">
            <v-app-bar :class="'animarDown ' + (isdarkTheme ? 'gradiente_dark': 'gradiente')" flat theme="dark">
                <v-app-bar-nav-icon @click="toggledrawer" color="white" v-if="draweble" aria-label="mobile"></v-app-bar-nav-icon>
                <v-row no-gutters v-if="showSearch">
                    <v-col cols="12" sm="12" md="8" lg="6">
                        <div style="margin-left: 2px">
                            <v-text-field density="compact" name="search" color="white" v-model="model"
                                          :search-input.sync="pesquisa" class="inp"
                                          @keyup="Pesquisar" solo-inverted hide-details prepend-inner-icon="mdi-magnify"
                                          placeholder="Pesquisar">
                                <template v-slot:append>
                                    <v-btn v-if="showBtnFilter" class="bnt_primary" @click="toggleModalFiltro()"
                                           size="small" aria-label="filtro">
                                        <v-icon size="large" aria-label="filtro">mdi-filter</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </div>

                    </v-col>
                </v-row>

                <slot name="more_content"></slot>

                <!--<div style="margin-right: 5%">-->
                    <!--<v-btn v-if="showBtnFilter" @click="toggleModalFiltro()" text aria-label="slider">-->
                        <!--<v-icon aria-label="slider">fa fa-sliders</v-icon>-->
                    <!--</v-btn>-->
                <!--</div>-->
                <v-spacer v-if="!showSearch"></v-spacer>
                <slot name="p_toolbar"></slot>


            </v-app-bar>

        </div>
        <v-container fluid>
            <slot name="content"></slot>

        </v-container>
    </div>
</template>

<script>
  /*eslint-disable*/
  import {mapActions, mapGetters, mapState} from 'vuex'
  import miscMixin from '../helpers/misc'
  import TokenExpirationTimer from './TokenExpirationTimer'

  export default {
    name: 'PForm',
    components: {
      TokenExpirationTimer
    },
    mixins: [miscMixin],
    props: {
      showSearch: {
        type: Boolean,
        default: false
      },
      showBtnFilter: {
        type: Boolean,
        default: false
      },
      draweble: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        left: '0px',
        left2: '260px',
        model: null,
        hide: null,
        pesquisa: null,
      }
    },
    methods: {
      ...mapActions('geral', [
        'setShowFilter',
        'setModalFiltro',
        'toggleModalFiltro',
        'toggleMiniVariant',
        'toggledrawer',
        'setIsNotPform',
        'setSearch']),
      changMinivariant() {
        this.toggleMiniVariant()
        this.left = this.miniVariant ? '2px' : '240px'
        this.left2 = this.miniVariant ? '0px' : '260px'
      },
      Pesquisar(e) {
        e = e || window.event
        const code = e.which || e.keyCode

        if (code === 13) { // ENTER
          this.setSearch(this.model)
        }
      },
    },
    created() {
      this.model = this.validarCampo(this.search) ? this.search : ''
      this.setSearch(this.model)
    },
    computed: {
      ...mapGetters('geral', {search: 'getSearch'}),

      ...mapState('geral', {
        isdarkTheme: 'isdarkTheme',
        drawer: 'drawer',
        modalFiltro: 'modalFiltro',
        miniVariant: 'miniVariant',
        clipped: 'clipped',
        showFilter: 'showFilter',
      }),
    },
    watch: {
      'model': function() {
        if (!this.validarCampo(this.model)) {
          this.setSearch('')
        }
      },
      pesquisa: function(val) {
        if (this.validarCampo(val)) {
          this.setSearch(val)
        }
      },
    },
    mounted() {
      var _this = this
      window.onscroll = function() {
        _this.hide = document.documentElement.scrollTop <= 50
      }
    }
  }
</script>
<style scoped>
    #header {
        display: block;
        position: fixed;
        top: 0;
        left:0;

        width: 100%;
        background: transparent;
        /*text-align: center;*/
        z-index: 100;

    }

</style>
